.menu--desktop {
    display: block;
  }
  .header--toggle,
  .menu--mobile {
    display: none;
  }
  @media (max-width: 1100px) {
    .menu--desktop {
      display: none;
    }
    .header--toggle,
    .menu--mobile {
      display: block;
    }
    .hamburger-nav {
      display: inherit !important;
    }
  }
  .menu .menu__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
  .menu__child-toggle-icon {
    display: inline-block;
    margin-left: 4px;
    transition: transform 0.2s ease;
  }
  .menu__child-toggle-icon svg {
    height: 6px;
    width: 12px;
  }
  .menu__item--open .menu__child-toggle-icon {
    transform: rotate(-180deg);
  }
  .menu__item {
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
    position: relative;
  }
  .menu__item .menu__link {
    display: inline-block;
    padding: 8px;
  }
  .menu__item a {
    color: #000;
    text-decoration: none;
  }
  .menu--desktop .menu__item--depth-1 > .menu__link:after {
    background-color: #000;
    bottom: -24px;
    content: "";
    display: none;
    height: 1px;
    left: 8px;
    position: absolute;
    right: 8px;
  }
  .menu__item--depth-1 > .menu__link:hover.menu__link:after {
    display: block;
  }
  .menu__item--depth-1.menu__item--open > .menu__link:after,
  .menu__item--depth-1 > .menu__link.menu__link--active-link:after {
    display: block;
    height: 2px;
  }
  .menu--desktop .menu__submenu {
    background-color: #f6f2ec;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 36px 16px -16px #0000001f;
    color: var(--deazy-black);
    /* display: none;  */
    left: 8px;
    min-width: 240px;
    padding: 12px;
    position: absolute;
    top: calc(100% + 24px);
  }

/* .menu--desktop .menu__submenu:hover {
    display: block;
} */

  .menu--desktop .menu__submenu.no-list {
    padding-left: 12px;
  }
  .menu__item--open .menu__submenu {
    display: block;
  }
  .menu--desktop .menu__submenu .menu__link {
    align-items: center;
    border-radius: 1000px;
    color: inherit;
    display: inline-flex;
    justify-content: space-between;
    padding: 4px 12px;
    width: 100%;
  }
  .menu--desktop .menu__submenu .menu__link:after {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='12' fill='none' viewBox='0 0 8 14'%3E%3Cpath stroke='%23141414' d='m1 1 6 6-6 6'/%3E%3C/svg%3E");
    display: none;
    width: 6px;
  }
  .menu--desktop .menu__submenu .menu__link:hover {
    background-color: #fff;
  }
  .menu--desktop .menu__submenu .menu__link:hover.menu__link:after {
    display: block;
  }
  .menu--mobile {
    margin-top: 40px;
  }
  .menu--mobile .menu__wrapper {
    flex-direction: column;
    gap: 16px;
  }
  @media (max-width: 1100px) {
    .header__navigation.open .header__column,
    .header__navigation.open .header__container,
    .header__navigation.open .header__row-2 {
      height: 100%;
    }
    .header__navigation.open .header__row-2 {
      justify-content: flex-start;
    }
    .header__navigation.open .header--links {
      flex-grow: 1;
    }
  }
  .menu--mobile .menu__item {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  .menu--mobile .menu__link {
    border-radius: 10000px;
    padding: 16px;
    width: 100%;
  }
  .menu--mobile .menu__item a {
    color: #fff;
  }
  .menu--mobile .menu__item--depth-1 > .menu__link--active-link {
    background: #ffffff0d;
  }
  .menu--mobile .menu__child-toggle-icon {
    margin-right: 8px;
    transform: rotate(-90deg);
  }
  .menu--mobile .menu__child-toggle-icon svg {
    height: 9px;
    width: 18px;
  }
  .menu--mobile .menu__child-toggle-icon svg path {
    stroke: #fff;
    stroke-width: 2px;
  }
  .menu--mobile .menu__link--toggle {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .menu--mobile .menu__submenu {
    display: none;
  }
  .menu--mobile .menu__item--open .menu__submenu {
    display: block;
    margin-top: 60px;
  }
  .menu__item--hidden {
    display: none;
  }
  .menu--mobile .menu__submenu .menu__link {
    font-size: 24px;
    line-height: 32px;
  }
  .menu--mobile .menu__item--open .menu__link--toggle {
    flex-direction: row-reverse;
  }
  .menu--mobile .menu__item--open .menu__child-toggle-icon {
    transform: rotate(90deg);
  }
  .menu--mobile .menu__item--open .menu__link--toggle > span:first-child {
    flex: 1;
    padding-right: 40px;
    text-align: center;
  }
  