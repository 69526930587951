.section-three-main-div {
    display: grid;
    grid-template-rows: 1fr 50px;
    justify-items: center;
    row-gap: 1rem;
    /* background-color: rgb(250, 251, 253); */
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
  }
  
  .quotes-img-right {
    justify-self: end;
    /* background-image: url(../../assets/left.png); */
    margin-right: 20px;
  }
  
  .quotes-img-left {
    /* background-image: url(../../assets/right.png); */
    margin-left: 20px;
  }
  
  .section-three-sub-div-one {
    display: grid;
    grid-template-columns: 10% 1fr 10%;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
  }
  
  .main-quotes-div {
    /* width: 1110px; */
    transition: 1s;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  
  .quotes-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 25px;
    height: 23px;
  }
  
  /* ------------------ */
  .para {
    font-family: "Lato", sans-serif;
    font-size: 18px;
  }
  
  .subText {
    margin-top: 1rem;
    font-size: 14px;
    color: #999999;
  }
  
  .myDot {
    display: inline-block;
    cursor: pointer;
    border: none;
    padding: 5.5px;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 5px;
    background: rgb(130, 126, 119);
  }
  
  .myDot--active {
    background: rgb(24, 24, 21);
  }
  
  .not-visible {
    opacity: 0;
    transition: 1s;
  }
  
  @media (max-width: 767px) {
  
    .section-three-sub-div-one {
      grid-template-columns: 15% 1fr 15%;
    }
  }