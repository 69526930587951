.scroll {
    height: 240px;
    overflow-y: auto;
  }
  
  .scroll:active::-webkit-scrollbar-thumb,
  .scroll:focus::-webkit-scrollbar-thumb,
  .scroll:hover::-webkit-scrollbar-thumb {
      visibility: visible;
  }
  .scroll::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      visibility: hidden;
  }
  
  .scroll::-webkit-scrollbar {
      width: 4px;
      height: 4px;
  }.tech-filters{margin-bottom:20px}.tech-filters-wrapper{margin:96px 96px 0;text-align:center}.tech-filters-wrapper .button{margin:0 auto}.tech-filters-wrapper h3{font-family:Work Sans;font-weight:400;text-align:center}.skills-list{margin:0 -40px 40px;max-height:264px;min-height:264px;padding-bottom:66px}.skills-list .simplebar-content{display:flex;flex-wrap:wrap;gap:2rem;justify-content:center;margin:40px}.skills-list .simplebar-content:before{content:unset}.skills-list .simplebar-content-wrapper:after{background:#fff;background:linear-gradient(180deg,hsla(0,0%,100%,0) 0,var(--deazy-cream) 100%);bottom:0;content:"";display:block;height:112px;left:0;margin-top:-112px;pointer-events:none;position:sticky;width:100%;z-index:2}
.skill-pill{align-items:center;background-color:#fff;border-radius:16px;box-shadow:0 0 40px rgba(0,0,0,.08);display:inline-flex;margin-left:5px;margin-top:5px; flex:1 0 20%;font-size:15px;height:56px;justify-content:flex-start;max-width:176px;padding:12px}.skill-pill img{height:32px;margin-right:12px;opacity:.75;width:32px}@media (max-width:767px){.tech-filters-wrapper{margin:72px 0 0}.skills-list .simplebar-content{display:grid;gap:16px;grid-template-columns:repeat(2,1fr)}.skill-pill{max-width:100%}}