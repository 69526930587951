
  .l-wrap {
      max-width: 600px;
      margin: 0 auto;
      padding: 30px 0;
    }
    
    /* Parsley CSS */
    
    .parsley-success {
      border-color: #28a745;
      padding-right: calc(1.5em + .75rem);
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
      background-repeat: no-repeat;
      background-position: center right calc(.375em + .1875rem);
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
    
    .parsley-error {
      border-color: #dc3545;
      padding-right: calc(1.5em + .75rem);
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'><path stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/><circle r='.5'/><circle cx='3' r='.5'/><circle cy='3' r='.5'/><circle cx='3' cy='3' r='.5'/></svg>");
      background-repeat: no-repeat;
      background-position: center right calc(.375em + .1875rem);
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
    label.parsley-error {
        border: none;
        background-image: none;
    }
  
    .parsley-errors-list > li {
        color:  #f70226;
    }
    
    .parsley-errors-list {
      margin: 2px 0 3px;
      padding: 0;
      list-style-type: none;
      font-size: 0.9em;
      line-height: 0.9em;
      opacity: 0;
    
      transition: all .3s ease-in;
      -o-transition: all .3s ease-in;
      -moz-transition: all .3s ease-in;
      -webkit-transition: all .3s ease-in;
    }
    
    .parsley-errors-list.filled {
      opacity: 1;
    }